import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="A" transform="translate(35.000000, 25.000000)">
        <path
          d="M3,3,L24,3,M20,3,L20,36,L3,36,L3,25"
          fill="currentColor"
        />
      </g>
      {/* <path
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      /> */}
    </g>
  </svg>
);

export default IconLoader;
